<template>
  <div class="div-content">
    <v-container class="fill-height elevation-12" fluid>
      <v-row align="center" justify="center">
        <v-col cols="3">
          <v-form ref="loginForm">
            <v-card class="card-content elevation-12" rounded="lg">
              <v-card-text class="fill-height text-center">
                <v-container class="fill-height">
                  <v-row class="fill-height">
                    <v-col align-self="start" cols="12" style="height: 15%">
                      <div class="text-right secondary--text text-h6 text-uppercase font-weight-bold">
                        {{ appName }}
                      </div>
                      <v-divider class="mx-5 warning"></v-divider>
                      <!-- <v-img height="80" contain :src="pathLogo" /> -->
                    </v-col>
                    <v-col align-self="start" cols="12" style="height: 75%; overflow-y: auto">
                      <v-text-field
                        ref="username"
                        append-icon="mdi-account-outline"
                        label="Username"
                        name="login"
                        type="text"
                        v-model="user.username"
                        class="my-6"
                        @keyup.enter="handleSubmit"
                        :rules="[rules.required]"
                      />
                      <v-text-field
                        id="password"
                        append-icon="mdi-lock-outline"
                        label="Password"
                        name="password"
                        type="password"
                        v-model="user.password"
                        @keyup.enter="handleSubmit"
                        :rules="[rules.required]"
                      />
                    </v-col>
                    <v-col align-self="end" cols="12" style="height: 10%">
                      <v-btn large ref="loginBtn" block color="secondary" @click="login" class="text-h6">{{ tLabel("Login") }}</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import FormatsMixins from "@/mixins/FormatsMixins";
import ToastMixin from "../mixins/ToastMixin";
import i18n from "../i18n";

export default {
  name: "login",
  title: i18n.t("Login"),
  components: {},
  data() {
    return {
      customer: process.env.VUE_APP_CUSTOMER,
      user: {
        username: "",
        password: "",
      },
    };
  },
  mixins: [ToastMixin, FormatsMixins],
  computed: {
    pathLogo() {
      return this.customer ? "assets/" + this.customer + "/" + this.customer + ".jpg" : "assets/milos-logo.png";
    },
  },
  mounted() {
    this.$refs["username"].focus();
  },
  methods: {
    handleSubmit(e) {
      console.log("handleSubmit e", e);
      e.preventDefault();
      this.login();
    },
    async login() {
      try {
        const valid = this.$refs.loginForm.validate();
        if (!valid) {
          return;
        }
        await this.$store.dispatch("authentication/login", this.user);
        this.$store.commit("configuration/clearFilters");
        this.$router.push({ path: "/" });
      } catch (e) {
        this.showError(`Accesso non riuscito: ${e.message}`);
      }
    },
  },
};
</script>

<style scoped>

.div-content-prova {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: 170;
  transform: rotate(deg);
  background-image: 
    conic-gradient(from 160deg at 70px 168.5px, #d8e1ff 0deg 90deg, transparent 90deg),
    conic-gradient(from 21deg at 168px 71px, #d8e1ff 0deg 90deg, transparent 90deg),
    conic-gradient(from 21deg at 141px 141px, #89d2fc 0deg 114deg, transparent 114deg),
    conic-gradient(from -21deg at 96.5px 44px, #1483CA 0deg 131.5deg, transparent 131.5deg), 
    conic-gradient(from -70deg at 43.5px 97px, #d8e1ff 0deg 115.5deg, transparent 115.5deg), 
    conic-gradient(from 134.5deg at 141px 141px, #1483CA 0deg 114.5deg, transparent 114deg),
    conic-gradient(from 160deg at 43.5px 97px, #89d2fc 0deg 130.5deg, transparent 120deg 360deg);
  background-color: skyblue;
  background-size: 195px 195px;
  background-position: -30px -30px;
  background-repeat: repeat, repeat;
}

.div-content {
  /* width: 200px;
        height: 600px; */
  width: 100%;
  height: 100%;
  background-color: #e8eaf6;
  /* opacity: 0.8; */
  /* background: repeating-linear-gradient(-45deg, #c5cae9, #c5cae9 5px, #e5e5f7 5px, #e5e5f7 25px); */
  position: absolute; /*Can also be `fixed`*/
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  /*Solves a problem in which the content is being cut when the div is smaller than its' wrapper:*/
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
}

.card-content {
  width: 23%;
  height: 60%;
  position: absolute; /*Can also be `fixed`*/
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  /*Solves a problem in which the content is being cut when the div is smaller than its' wrapper:*/
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
}
</style>
